﻿/* _ezentrum_customize.scss for individual styling elements */
/* ########## start: import ezentrum_variables ########## */

@use "ezentrum_variables";
@use "../scss_libraries/ezentrum_mixins";

/* ########## stop: import ezentrum_variables ########## */

body {
	background-color:ezentrum_variables.$primary-color;
}
h1, h2, h3, h4, h5, h6 {}
button, .button {
	&.secondary {
		background-color:ezentrum_variables.$secondary-color;
	}
}


#ez_wrapper {
	background-color:ezentrum_variables.$primary-color;
	#ez_header_fullwidth {
		.logo {
			width:220px;
			height: auto;
		}
		#ez_header1_fullwidth {
			#ez_header1_wrapper {
				margin-top:ezentrum_variables.$global-margin;
				@include ezentrum_mixins.for-size(tablet-landscape-up) {
					border-left:1rem solid ezentrum_variables.$header1-background-color;
					border-right:1rem solid ezentrum_variables.$header1-background-color;
				}
				.ez_header1_section {
					.ez_header1_item {
						.phone {
							display:block;
							margin-top:0.4rem;
							font-size:0.9rem;
						}
						#ez_headernavi {
							ul {
								list-style-type: none;
								li {
									float:left;
									a {
										display:block;
										padding:0.5rem;
										font-weight:normal;
									}
									&:first-child {
										border-right:1px solid ezentrum_variables.$white;
									}
									&:last-child {
										a {
											padding-right:0;
										}
									}
								}
							}
						}
						.social_icon {
							margin-top:50px;
						}
					}
				}
			}
		}
		#ez_header2_fullwidth {
			border-bottom:1px solid ezentrum_variables.$gray;
			#ez_header2_wrapper {
				@include ezentrum_mixins.for-size(tablet-landscape-up) {
					border-left:1rem solid ezentrum_variables.$header2-background-color;
					border-right:1rem solid ezentrum_variables.$header2-background-color;
				}
				.ez_header2_section {
					width:100%;
					&.ez_searchform_wrapper {
						width:108%;
						.ez_header2_item {
							width:calc(100% + calc(2 * #{ezentrum_variables.$global-margin}));
						}
					}
					@include ezentrum_mixins.for-size(tablet-portrait-up) {
						width:100%;
						&.ez_searchform_wrapper {
							width:100%;
							.ez_header2_item {
								width:100%;
							}
						}
					}
					@include ezentrum_mixins.for-size(desktop-up) {
						width:50%;
						&.ez_searchform_wrapper {
							width:50%;
						}
					}
					.ez_header2_item {
						width:100%;
						.ez_searchform {
							width:100%;
							.ez_searchcontainer {
								input[type="text"],
								.ez_searchbutton {
									background-color:rgba(255,255,255,0.3);
									color:ezentrum_variables.$white;
									i {
										color:ezentrum_variables.$white;
									}
								}
							}
						}
						nav {
							ul li {
								a, span {
									font-weight:bold;
								}
								.dropdown-menu {
									a {
										color:ezentrum_variables.$body-font-color;
										font-weight:normal;
									}
								}
								.ez_to_cart_button {
									i, span {
									color:ezentrum_variables.$white;
									}
								}
							}
							&.ez_headernavi_right {
								ul {
									list-style-type: none;
									li {
										float: left;
										margin-right:ezentrum_variables.$global-margin;
										&:last-child {
											margin-right:0;
										}
										a, span {
											font-weight:normal;
										}
										i.fas {
											font-size:ezentrum_variables.$body-font-size;
											padding-top: 5px;
										}
										span {
											padding-left:ezentrum_variables.$global-padding;
										}
									}
								}
							}
						}
						.ez_headernavi_left,
						.ez_headernavi_right {
							ul.ez_level1 {
								li {
									ul.ez_level2 {
										li {
											position:relative;
											ul.ez_level3 {
												position:absolute;
												display:none;
												top:0;
												left:100%;
												li {
												}
											}
											&:hover ul.ez_level3 {
												display:block;
												opacity:1;
											}
										}
									}
								}
							}
							ul li.ez_cartpreview {
								position:relative;
								span.ez_cartpreview_positions {
									position:absolute;
									top:-18px;
									right:0;
									margin:0;
									padding:0 !important;
									width:0.8 * ezentrum_variables.$body-line-height;
									height:0.8 * ezentrum_variables.$body-line-height;
									border-radius:50%;
									@include ezentrum_mixins.small-text;
									text-align:center;
									font-weight:bold !important;
									background-color:#c60f13;
									color:ezentrum_variables.$white !important;
								}
							}
							#cart_preview {
								display: none;
								overflow: auto;
								color: #000;
								position: absolute;
								left: unset !important;
								right: 0;
								width: 400px !important;
								background-color: #fff;
								border: 1px solid black;
								top: 67px !important;
								border-top: none;
								padding-left: 20px;
								padding-right: 20px;
								outline: unset;
								.ez_cartpreview_item {
									line-height:ezentrum_variables.$body-line-height;
									.ez_cartpreview_menge {
										margin-top: 15%;
									}
								}
							}
						}
					}
					&:last-child {
						.ez_header2_item {
							@include ezentrum_mixins.flex-justify-content(flex-end);
							margin:0;
							padding:0;
						}
					}
				}
			}
		}
	}
	#ez_content_fullwidth {
		#ez_content_wrapper {
			background-color:ezentrum_variables.$white;
			@include ezentrum_mixins.for-size(tablet-landscape-up) {
				border-left:1rem solid ezentrum_variables.$primary-color;
				border-right:1rem solid ezentrum_variables.$primary-color;
			}
			#ez_sidenavi_container {
				.ez_level1 {
					&.ez_meinsortiment {
						margin-bottom:0;
					}
					> li {
						> a {
							background-color:ezentrum_variables.$primary-color;
							color:ezentrum_variables.$white;
						}
					}
				}
			}
			#ez_content {
				#index {
					.hersteller {
						.ez_griditem {
							@include ezentrum_mixins.display-flex;
							@include ezentrum_mixins.flex-align-items(center);
							@include ezentrum_mixins.flex-justify-content(center);
							min-height:160px;
							border:1px solid ezentrum_variables.$body-font-color;
							.logo {
								max-width:100%;
							}
						}
					}
					.kataloge {
						grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
						.ez_griditem {
							@include ezentrum_mixins.display-flex;
							@include ezentrum_mixins.flex-align-items(center);
							@include ezentrum_mixins.flex-justify-content(center);
							background-color:rgb(237,237,237);
							.katalog_cover {
								max-width:200px;
								max-height:200px;
							}
						}
					}
				}
				.ez_productlist {
					.ez_product_wrapper {
						background-color:rgb(237,237,237);
						border:none;
						.ez_product {
							.ez_productimage {
								background-color:#fff;
							}
							.ez_productname {
								margin-top:ezentrum_variables.$global-margin;
								h2 {
									font-family: "Lato", sans-serif;
									font-weight:700;
									text-align:center;
									line-height:1.4;
									color:ezentrum_variables.$body-font-color;
								}
							}
						}
					}
				}
				.ez_productdetail {
					.ez_productheadline_wrapper {
						display:none;
						margin:0;
						padding:0;
						.ez_productheadline {
						}
					}
					.ez_productimage_wrapper {
						.ez_additionalimages {
							.ez_thumb {
								width:100px;
								img {
									max-width:100px;
									max-height:100px;
								}
							}
						}
					}
					.ez_productdescription_wrapper {
						padding-left:ezentrum_variables.$global-padding;
					}
					.ez_productdetails_wrapper {
						margin-top: 2 * ezentrum_variables.$global-margin;
					}
				}
				.ez_checkout {
					img.productimage {
						max-width:160px;
						max-height:160px;
					}
				}
			}
		}
	}
	#ez_footer_fullwidth {
		#ez_footer1_fullwidth {
			#ez_footer1_wrapper {
				.ez_footer1_section {
					&.ez_footerlogo, &.ez_paymentmethods {
						img {
							max-width:100%;
						}
					}
					.ez_footer1_item {
						img.logo {
							display:inline-block;
							margin-bottom:ezentrum_variables.$global-margin;
							width:220px;
						}
					}
				}
			}
		}
	}
}
#ez_wrapper #ez_header_fullwidth {
	position:relative;
	#ez_mobilemenu_wrapper {
		position:absolute;
		@include ezentrum_mixins.display-flex;
		@include ezentrum_mixins.for-size(tablet-landscape-up) {
			display:none;
		}
	}
	#ez_header1_fullwidth #ez_header1_wrapper {
		@include ezentrum_mixins.flex-justify-content(flex-end);
		@include ezentrum_mixins.for-size(tablet-landscape-up) {
			@include ezentrum_mixins.flex-justify-content(space-between);
		}
		.ez_headernavi_right_wrapper {
			display:none;
			@include ezentrum_mixins.for-size(tablet-landscape-up) {
				@include ezentrum_mixins.display-flex;
			}
		}
	}
	#ez_header2_fullwidth #ez_header2_wrapper {
		.ez_searchform_wrapper {
			@include ezentrum_mixins.for-size(tablet-landscape-up) {
			}
		}
		.ez_headernavi_right_wrapper {
			display:none;
			@include ezentrum_mixins.for-size(tablet-portrait-up) {
				@include ezentrum_mixins.display-flex;
			}
			.ez_headernavi_right {
				ul {
					li.customer {
						display:none;
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							display:list-item;
						}
					}
				}
			}
		}
	}
}

.ez_availability {
	&.avail_good {}
	&.avail_medium {background-color: seagreen;}
	&.avail_bad {background-color: orange;}
}


/* ##### Bereich Freizeit - Sport ##### */
body.f_s {
	background-color:ezentrum_variables.$white;
	#ez_wrapper {
		background-color:ezentrum_variables.$white;
		#ez_header_fullwidth {
			#ez_header1_fullwidth {
				background-color:ezentrum_variables.$white;;
				#ez_header1_wrapper {
					@include ezentrum_mixins.for-size(tablet-landscape-up) {
						border-left:1rem solid ezentrum_variables.$white;
						border-right:1rem solid ezentrum_variables.$white;
					}
					.ez_header1_section {
						.ez_header1_item {
							#ez_headernavi {
								ul {
									li {
										a {
											color:ezentrum_variables.$primary-color;
										}
										&:first-child {
											border-right:2px solid ezentrum_variables.$primary-color;
										}
									}
								}
							}
						}
					}
				}
			}
			.ez_searchcontainer {
				.ez_searchinput {
				}
				.ez_searchbutton {
					background-color:ezentrum_variables.$white;
					i {
						color:ezentrum_variables.$body-font-color;
					}
					&:hover i {
						color:lighten(ezentrum_variables.$body-font-color, 20);
					}
				}
			}
			#ez_header2_fullwidth {
				background-color:ezentrum_variables.$header1-background-color;
				#ez_header2_wrapper {
					@include ezentrum_mixins.for-size(tablet-landscape-up) {
						border-left:1rem solid ezentrum_variables.$header1-background-color;
						border-right:1rem solid ezentrum_variables.$header1-background-color;
					}
				}
			}
		}
		#ez_content_fullwidth {
			#ez_content_wrapper {
				@include ezentrum_mixins.for-size(tablet-landscape-up) {
					border-left:1rem solid ezentrum_variables.$white;
					border-right:1rem solid ezentrum_variables.$white;
				}
			}
		}
	}
	#ez_mobilemenu-toggle {
		span.burgericon {
			background-color: ezentrum_variables.$body-font-color;
		}
	}
}
