﻿/* _ezentrum_default.scss for global styling elements and styling of html tags */

@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "../scss_libraries/ezentrum_mixins";

html,
body {
	margin:0;
	padding:0;
	width: 100%;
	height: 100%;
	color: ezentrum_variables.$body-font-color;
	background-color:ezentrum_variables.$white;
	font-family: ezentrum_variables.$body-font-family;
	font-size: ezentrum_variables.$body-font-size;
	font-weight: ezentrum_variables.$body-font-weight;
	line-height: ezentrum_variables.$body-line-height;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	a {
		color: ezentrum_variables.$primary-color;
		&:hover {
			color:ezentrum_variables.$primary-color-hover;
		}
	}
}
h1, h2, h3, h4, h5, h6 {
	font-family: ezentrum_variables.$body-font-family;
	font-weight:700;
}
h1 {font-size: 1.5rem;}
h2 {font-size: 1.25rem;}
h3 {font-size: 1.125rem;} 
h4 {font-size: 1rem;}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="text"], input[type="number"], input[type="email"], input[type="password"], textarea {
	margin:0 0 ezentrum_variables.$global-margin 0;
	padding:0.5 * ezentrum_variables.$global-padding;
	width:100%;
	min-height:calc(#{ezentrum_variables.$body-font-size} + (1.5 * #{ezentrum_variables.$global-padding}));
	line-height:calc(1.2 * #{ezentrum_variables.$body-font-size});
	border-top: 1px solid #919191;
	border-right: 1px solid #dadada;
	border-bottom: 1px solid #dadada;
	border-left: 1px solid #919191;
	border-radius:ezentrum_variables.$global-radius;
	background-color:ezentrum_variables.$white;
	&:focus {
		background-color:darken (ezentrum_variables.$white, 20);
	}
}
input[type="number"] {
	-moz-appearance: textfield;
}
input:active,
input:focus,
input:focus-within {
	outline:none;
}

label {
	margin:0 0 ezentrum_variables.$global-margin 0;
	width:100%;
	input[type="radio"], input[type="checkbox"] {
		vertical-align:top;
		width:ezentrum_variables.$body-font-size;
		height:ezentrum_variables.$body-font-size;
		border-top: 2px solid #919191;
		border-left: 2px solid #919191;
		border-bottom: 2px solid #dadada;
		border-right: 2px solid #dadada;
	}
	input[type="radio"] ~ span,
	input[type="checkbox"] ~ span {
		display:block;
		float:right;
		margin:-2px 0 0 0;
		padding:0 0 0 ezentrum_variables.$global-padding;
		width:calc(100% - #{ezentrum_variables.$body-font-size} - #{ezentrum_variables.$global-padding});
	}
}

button, .button, input[type="button"], input[type="submit"], input.button, a.button {
	display:block;
	margin:0 0 ezentrum_variables.$global-margin 0;
	padding:0.5 * ezentrum_variables.$global-padding;
	width:100%;
	min-height:calc(#{ezentrum_variables.$body-font-size} + (1.5 * #{ezentrum_variables.$global-padding}));
	line-height:calc(1.2 * #{ezentrum_variables.$body-font-size});
	border:none;
	border-radius:ezentrum_variables.$global-radius;
	background-color:ezentrum_variables.$primary-color;
	color:ezentrum_variables.$white;
	text-align:center;
	cursor:pointer;
	&.secondary {
		background-color:ezentrum_variables.$secondary-color;
		&:hover {
			color:darken(ezentrum_variables.$white, 10);
			background-color:ezentrum_variables.$secondary-color-hover;
			text-decoration:none;
		}
	}
	&:hover {
		color:darken(ezentrum_variables.$white, 10);
		background-color:ezentrum_variables.$primary-color-hover;
		text-decoration:none;
	}
	&.button-padding-bot {
		padding-bottom: 1.1rem;
	}
	&.expanded {
		width:100%;
	}
}
a.button {
	padding-top:calc(0.5 * #{ezentrum_variables.$global-padding});
}
.ez_select {
	@include ezentrum_mixins.display-flex;
	@include ezentrum_mixins.flex-justify-content(space-between);
	width:100%;
	span {
		position:relative;
		margin:0 0 ezentrum_variables.$global-margin 0;
		width:100%;
		min-height:calc(#{ezentrum_variables.$body-font-size} + (1.6 * #{ezentrum_variables.$global-padding}));
		text-decoration: none;
		transition: background-color .3s;
		border-top: 1px solid #919191;
		border-right: 1px solid #dadada;
		border-bottom: 1px solid #dadada;
		border-left: 1px solid #919191;
		border-radius:ezentrum_variables.$global-radius;
		float: left;
		i {
			position:absolute;
			top:calc((0.4 * #{ezentrum_variables.$body-font-size}) + (0.1 * #{ezentrum_variables.$global-padding}));
			right:8px;
			color:ezentrum_variables.$body-font-color;
		}
		select {
			display: inline-block;
			margin: 0;
			padding:calc(0.5 * #{ezentrum_variables.$global-padding}) 1rem calc(0.5 * #{ezentrum_variables.$global-padding}) calc(0.5 * #{ezentrum_variables.$global-padding});
			width: 100%;
			line-height:calc(1.2 * #{ezentrum_variables.$body-font-size});
			font-size: ezentrum_variables.$body-font-size;
			border:none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			background-color: transparent;
			&:focus {
				outline:none;
			}
		}
	}
}
.ez_pageselect,
.ez_pagesort {
	float:right;
	a, button {
		padding: 0;
		text-decoration: none;
		color: black;
		transition: background-color .3s;
		border: 1px solid #ddd;
		float: left;
	}
	button.prev,
	button.next {
		width:unset;
		background-color:ezentrum_variables.$secondary-color;
		color:ezentrum_variables.$white;
		border:1px solid #919191;
		i {
			font-size:large; padding: .55rem 1rem;
		}
	}
	button.prev {
		border-right:none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	button.next {
		border-left:none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}
.ez_pageselect {
	span {
		border-radius:0;
	}
}
.ez_fields_menge {
	@include ezentrum_mixins.display-flex;
	@include ezentrum_mixins.flex-justify-content(space-between);
	@include ezentrum_mixins.flex-align-items(center);
	margin:0 0 ezentrum_variables.$global-margin 0;
	background-color:ezentrum_variables.$gray;
	border:1px solid ezentrum_variables.$gray;
	border-radius:ezentrum_variables.$global-radius;
	.ez_minus, .ez_plus, input[type="number"] {
		margin:0;
		padding:0;
		text-align:center;
		font-size: ezentrum_variables.$body-font-size;
	}
	.ez_minus, .ez_plus {
		padding:0;
		width:40px;
		flex:1 0 auto;
		color:ezentrum_variables.$white;
		cursor:pointer;
	}
	.ez_minus {}
	.ez_plus {}
	input[type="number"],
	input[type="number"]:focus,
	input[type="number"]:not(.browser-default):focus:not([readonly]) {
		margin-bottom:1px;
		width:calc(100% - 80px);
		min-height:calc(#{ezentrum_variables.$body-font-size} + (1.2 * #{ezentrum_variables.$global-padding}));
		background-color:ezentrum_variables.$white;
		border-bottom:none;
	}
}

hr {
	color: transparent;
	border-bottom: 1px solid ezentrum_variables.$gray;
	&.primary {
		border-bottom: 1px solid ezentrum_variables.$primary-color;
	}
	&.secondary {
		border-bottom: 1px solid ezentrum_variables.$secondary-color;
	}
}

.ez_warenkorbpreview {
	text-align: center;
	padding: 0;
	.ez_warenkorbpreview_button {
		padding: 0em;
		margin: 0em;
		.ez_warenkorbpreview_button_font {
			color: white;
		}
	}
}

img.ez_warenkorbpreview_img {
	width: 6rem;
}

.ez_productprice {
	text-align:right;
	.ez_normalpreis {}
	.ez_grundpreis {
		@include ezentrum_mixins.small-text;
		color:red;
		text-decoration:line-through;
	}
	.ez_sonderpreis {}
	.ez_basispreis {
		@include ezentrum_mixins.small-text;
	}
	.ez_currency {}
	.ez_addition {
		@include ezentrum_mixins.small-text;
	}
	.ez_sum {
		font-size:1.2rem;
		font-weight:bold;
	}
	sup {
		font-size:0.6em;
		font-weight:normal;
	}
}
.ez_price_info {
	margin:ezentrum_variables.$global-margin 0;
	padding:0;
	width:100%;
	@include ezentrum_mixins.small-text;
}

.ez_availability {
	display:inline-block;
	width:ezentrum_variables.$body-font-size;
	height:ezentrum_variables.$body-font-size;
	border-radius:50%;
	&.avail_good {background-color: seagreen;}
	&.avail_medium {background-color: orange;}
	&.avail_bad {background-color: orangered;}
}

.ez_sku {
	@include ezentrum_mixins.small-text;
}

.ez_languageselect {
	img.flag {
		height:ezentrum_variables.$body-font-size;
	}
}

#ez_wrapper {
	@include ezentrum_mixins.full-width;
	#ez_header_fullwidth {
		position:relative;
		@include ezentrum_mixins.full-width;
		height:ezentrum_variables.$header-height;
		#ez_header1_fullwidth {
			position:relative;
			@include ezentrum_mixins.display-flex;
			@include ezentrum_mixins.full-width;
			height:ezentrum_variables.$header1-height;
			font-family: ezentrum_variables.$header1-font-family;
			font-size: ezentrum_variables.$header1-font-size;
			color:ezentrum_variables.$header1-font-color;
			background-color:ezentrum_variables.$header1-background-color;
			overflow:hidden;
			#ez_header1_wrapper {
				@include ezentrum_mixins.display-flex;
				@include ezentrum_mixins.flex-justify-content(space-between);
				margin:0 auto;
				padding:0 ezentrum_variables.$global-padding;
				width:100%;
				max-width: ezentrum_variables.$global-width;
				.ez_header1_section {
					@include ezentrum_mixins.display-flex;
					@include ezentrum_mixins.flex-justify-content(space-between);
					margin:-1 * ezentrum_variables.$global-margin;
					padding:ezentrum_variables.$global-padding;
					.ez_header1_item {
						max-height:ezentrum_variables.$header-height;
						margin:-1 * ezentrum_variables.$global-margin;
						padding:ezentrum_variables.$global-padding;
						overflow:hidden;
						a {
							color:ezentrum_variables.$header1-link-color;
							&:hover {
								color:ezentrum_variables.$header1-link-color-hover;
							}
						}
						.ez_level1 {
							a {
								font-weight:bold;
								text-decoration:none;
								color:ezentrum_variables.$header1-link-color;
								&:hover {
									color:ezentrum_variables.$header1-link-color-hover;
								}
							}
						}
					}
				}
			}
		}
		#ez_header2_fullwidth {
			position:relative;
			@include ezentrum_mixins.display-flex;
			@include ezentrum_mixins.full-width;
			height:ezentrum_variables.$header2-height;
			color:ezentrum_variables.$header2-font-color;
			background-color:ezentrum_variables.$header2-background-color;
			#ez_header2_wrapper {
				@include ezentrum_mixins.display-flex;
				margin-top:0;
				margin-right:auto;
				margin-bottom:0;
				margin-left:auto;
				padding:0 ezentrum_variables.$global-padding;
				width: ezentrum_variables.$global-width;
				@include ezentrum_mixins.flex-justify-content(space-between);
				.ez_header2_section {
					@include ezentrum_mixins.display-flex;
					align-items: center;
					margin:-1 * ezentrum_variables.$global-margin;
					padding:ezentrum_variables.$global-padding;
					.ez_header2_item {
						@include ezentrum_mixins.display-flex;
						margin:-1 * ezentrum_variables.$global-margin;
						padding:ezentrum_variables.$global-padding;
						a {
							color:ezentrum_variables.$header2-link-color;
							&:hover {
								color:ezentrum_variables.$header2-link-color-hover;
							}
						}
						.ez_searchform {
							width:inherit;
							.ez_searchcontainer {}
						}
					}
				}
			}
		}
		#ez_header3_fullwidth {
			position:relative;
			@include ezentrum_mixins.display-flex;
			@include ezentrum_mixins.full-width;
			height:ezentrum_variables.$header3-height;
			color:ezentrum_variables.$header3-font-color;
			background-color:ezentrum_variables.$header3-background-color;
			#ez_header3_wrapper {
				@include ezentrum_mixins.display-flex;
				margin-top:0;
				margin-right:auto;
				margin-bottom:0;
				margin-left:auto;
				padding:0 ezentrum_variables.$global-padding;
				width: ezentrum_variables.$global-width;
				@include ezentrum_mixins.flex-justify-content(space-between);
				.ez_header3_section {
					@include ezentrum_mixins.display-flex;
					align-items: center;
					margin:-1 * ezentrum_variables.$global-margin;
					padding:ezentrum_variables.$global-padding;
					width:100%;
					.ez_header3_item {
						@include ezentrum_mixins.display-flex;
						margin:-1 * ezentrum_variables.$global-margin;
						padding:ezentrum_variables.$global-padding;
						width:100%;
						a {
							color:ezentrum_variables.$header3-link-color;
							&:hover {
								color:ezentrum_variables.$header3-link-color-hover;
							}
						}
						.ez_searchform {
							width:inherit;
							.ez_searchcontainer {}
						}
					}
					&:last-child {
						@include ezentrum_mixins.flex-justify-content(flex-end);
						.ez_header3_item {
							@include ezentrum_mixins.flex-justify-content(flex-end);
						}
					}
				}
			}
		}
		#ez_categoryimage {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:ezentrum_variables.$header-height;
			z-index:0;
		}
/*
		#slide-out {
			li {
				a {
					padding: 0 16px;
				}
				#logo-container {
					height: 115px;
					margin-bottom: 32px;
				}
				#shop-collapse {
					position: relative;
					i {
						position: absolute;
						right: 0;
					}
				}
			}
			li.small-nav-logo {
				margin-bottom: 105px;
			}
			li.search {
				position: absolute;
				left: 0;
				right: 0;
				top: 160px;
				margin-top: 1px;
				padding: 1px 0 0 0;
				z-index: 2;
				.search-wrapper {
					color: #777;
					margin-top: -1px;
					margin-bottom: -1px;
					border-top: 1px solid rgba(0, 0, 0, 0.14);
					border-bottom: 1px solid rgba(0, 0, 0, 0.14);
					transition: margin .25s ease;
					position: relative;
					[type="submit"] {
						-webkit-appearance: unset;
						-moz-appearance: unset;
						-ms-progress-appearance: unset;
						appearance: unset;
					}
					[data-ez-open="true"] {
						display: inline-block;
					}
					.search {
						color: #777;
						display: block;
						font-size: 16px;
						font-weight: 300;
						width: 100%;
						height: 62px;
						margin: 0;
						box-sizing: border-box;
						padding: 0 45px 0 30px;
						border: 0;
					}
					i {
						margin-right: 5px;
					}
					.search_results {
						margin: 5px;
						border: 1px solid rgba(0,0,0,.5);
						border-radius: 0px;
						background-color: #fff;
						position: absolute;
						height: 400px;
						top: 70px;
					}
				}
			}
		}
*/
	}
	#ez_content_fullwidth {
		@include ezentrum_mixins.full-width;
		#ez_content_wrapper {
			position:relative;
			margin:0 auto ezentrum_variables.$global-margin auto;
			padding:0 ezentrum_variables.$global-padding;
			max-width: ezentrum_variables.$global-width;
			@include ezentrum_mixins.display-flex();
			@include ezentrum_mixins.flex-justify-content(space-between);
			flex-flow:row wrap;
			.ez_content_reduced_width {
				margin:0 auto;
				max-width:ezentrum_variables.$reduced_width;
			}
			#ez_sidenavi_container {
				display:none;
				@include ezentrum_mixins.for-size(tablet-landscape-up) {
					display:block;
					margin-right:ezentrum_variables.$global-margin;
					padding-right:ezentrum_variables.$global-padding;
					padding-bottom:ezentrum_variables.$global-padding;
					width:ezentrum_variables.$sidenavi-tablet-width;
				}
				@include ezentrum_mixins.for-size(desktop-up) {
					width:ezentrum_variables.$sidenavi-width;
				}
				ul {
					list-style-type:none;
					padding-left:0;
				}
				.ez_level1 {
					word-wrap: break-word;
					-moz-hyphens: auto;
					-o-hyphens: auto;
					-webkit-hyphens: auto;
					-ms-hyphens: auto;
					hyphens: auto;
					> li {
						a, span {
							display:block;
							margin-bottom:0.5 * ezentrum_variables.$global-margin;
							padding:0.5 * ezentrum_variables.$global-padding;
							color:ezentrum_variables.$body-font-color;
							text-decoration:none;
							&:hover {
								color:lighten(ezentrum_variables.$body-font-color, 20);
							}
							&.ez_container {
								position:relative;
								padding-right:1.2rem;
								i {
									position:absolute;
									top:0.8rem;
									right:0.6rem;
								}
							}
						}
						> a, > span {
							font-weight:bold;
							background-color:lighten(ezentrum_variables.$gray, 20);
							&:hover {
								background-color:lighten(ezentrum_variables.$gray, 22);
							}
						}
						.ez_level2 {
							> li {
								> a, > span {
									font-weight:normal;
									background-color:lighten(ezentrum_variables.$gray, 30);
									&:hover {
										background-color:lighten(ezentrum_variables.$gray, 32);
									}
									&.selected {
										font-weight:bold;
									}
								}
								.ez_level3 {
									> li {
										> a, > span {
											font-size:0.8rem;
											background-color:lighten(ezentrum_variables.$gray, 30);
											&:hover {
												background-color:lighten(ezentrum_variables.$gray, 32);
											}
											&.selected {
												font-weight:bold;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.ez_breadcrumbs {
				width:100%;
				margin:ezentrum_variables.$global-margin 0;
				padding:0;
				.ez_breadcrumb_spacer {
					margin:ezentrum_variables.$global-margin;
					padding:0;
				}
				.ez_breadcrumb_item {
					margin:ezentrum_variables.$global-margin 0;
					padding:0;
					word-wrap: break-word;
					-webkit-hyphens: auto;
					-ms-hyphens: auto;
					hyphens: auto;
				}
			}
			#ez_content {
				margin: 0;
				padding:0 0 ezentrum_variables.$global-padding 0;
				flex:1 0 auto;
				max-width:ezentrum_variables.$content-smartphone-width;
				@include ezentrum_mixins.for-size(tablet-landscape-up) {
					max-width:ezentrum_variables.$content-tablet-width;
				}
				@include ezentrum_mixins.for-size(desktop-up) {
					max-width:ezentrum_variables.$content-width;
				}
				#ez_login {
					.ez_register {
						.ez_customer_register {
							#show_register_form {
								display:none;
							}
							#show_register_form + #show_register_form_checkbox {
								padding-right:2rem;
								background-image:url(/resources/images/icons/fi-arrow-down.svg);
								background-repeat:no-repeat;
								background-position:95% 50%;
								background-size:1rem;
							}
							#show_register_form + #show_register_form_checkbox + #register_form {
								clear:both;
								max-height:0;
								overflow:hidden;
								transition: max-height 0.5s;
							}
							#show_register_form:checked + #show_register_form_checkbox {
								background-image:url(/resources/images/icons/fi-arrow-up.svg);
							}
							#show_register_form:checked + #show_register_form_checkbox + #register_form {
								max-height:48rem;
								overflow:hidden;
							}
						}
					}
				}

				#kasse {
					#form_kasse {
						#agb_accept,
						#datenschutz_accept,
						#newsletter_accept {
							display: flex;
							justify-content: center;
							align-items: center;

							input[type=checkbox] {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
	#ez_footer_fullwidth {
		position:relative;
		@include ezentrum_mixins.full-width;
		margin-top:ezentrum_variables.$global-margin;
		font-family:ezentrum_variables.$footer-font-family;
		font-size:ezentrum_variables.$footer-font-size;
		color:ezentrum_variables.$footer-font-color;
		a {
			color:ezentrum_variables.$footer-link-color;
			&:hover {
				color:ezentrum_variables.$footer-link-color-hover;
			}
		}
		#ez_footer1_fullwidth {
			position:relative;
			@include ezentrum_mixins.full-width;
			background-color:ezentrum_variables.$footer1-background-color;
			color:ezentrum_variables.$footer1-font-color;
			#ez_footer1_wrapper {
				@include ezentrum_mixins.display-flex;
				@include ezentrum_mixins.flex-justify-content(space-between);
				@include ezentrum_mixins.flex-flow(column,nowrap);
				@include ezentrum_mixins.for-size(tablet-landscape-up) {
					@include ezentrum_mixins.flex-flow(row,nowrap);
				}
				margin-top: -1 * ezentrum_variables.$global-margin;
				margin-right:auto;
				margin-bottom: -1 * ezentrum_variables.$global-margin;
				margin-left:auto;
				padding: ezentrum_variables.$global-padding;
				max-width: ezentrum_variables.$global-width;
				.ez_footer1_section {
					@include ezentrum_mixins.display-flex;
					@include ezentrum_mixins.flex-justify-content(space-between);
					flex-flow:row wrap;
					margin:-1 * ezentrum_variables.$global-margin 0;
					padding:ezentrum_variables.$global-padding;
					.ez_footer1_item {
						a {
							color:ezentrum_variables.$footer-link-color;
							&:hover {
								color:ezentrum_variables.$footer-link-color-hover;
							}
						}
					}
				}
			}
		}
		#ez_footer2_fullwidth {
			position:relative;
			@include ezentrum_mixins.full-width;
			background-color:ezentrum_variables.$footer2-background-color;
			color:ezentrum_variables.$footer2-font-color;
			#ez_footer2_wrapper {
				@include ezentrum_mixins.display-flex;
				@include ezentrum_mixins.flex-justify-content(space-between);
				margin-top: -1 * ezentrum_variables.$global-margin;
				margin-right:auto;
				margin-bottom: -1 * ezentrum_variables.$global-margin;
				margin-left:auto;
				padding: ezentrum_variables.$global-padding;
				max-width: ezentrum_variables.$global-width;
				.ez_footer2_section {
					@include ezentrum_mixins.display-flex;
					@include ezentrum_mixins.flex-justify-content(space-between);
					flex-flow:row wrap;
					margin:-1 * ezentrum_variables.$global-margin auto;
					padding:ezentrum_variables.$global-padding;
					.ez_footer2_item {
						color:ezentrum_variables.$footer2-font-color;
					}
				}
			}
		}
	}
}
.ez_gridcontainer {
	margin:0 0 ezentrum_variables.$global-margin 0;
	width:100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(ezentrum_variables.$grid-item-width, 1fr));
	grid-gap:ezentrum_variables.$global-margin;
	.ez_griditem {
		margin:0;
		padding:ezentrum_variables.$global-padding;
		border-radius:ezentrum_variables.$global-radius;
	}
}

.lightbox-container {
	.inner {
		.item.image {
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

#slide-out {
	.ez_cartpreview {
		position:relative;
		span.ez_cartpreview_positions {
			position:absolute;
			top:5px;
			margin:0;
			padding:0 !important;
			width:0.8 * ezentrum_variables.$body-line-height;
			height:0.8 * ezentrum_variables.$body-line-height;
			border-radius:50%;
			@include ezentrum_mixins.small-text;
			text-align:center;
			font-weight:bold !important;
			background-color:#c60f13;
			color:ezentrum_variables.$white !important;
		}
	}
}


/* only for testing */
#ez_wrapper {
/*
	#ez_header1_wrapper, #ez_header2_wrapper, #ez_content_wrapper, #ez_footer1_wrapper, #ez_footer2_wrapper {background-color:lightgrey;}
*/
}

#lieferanschrift_neu_form {
	max-height:0;
	overflow:hidden;
	transition: max-height 1s;
	label {
		display:none;
	}
}
input#lieferanschrift_neu:checked + #lieferanschrift_neu_form {
	max-height:500px;
}

#ez_mobilemenu-toggle {
	display:block;
	@include ezentrum_mixins.for-size(tablet-landscape-up) {
		display:none;
	}
	position: relative;
	top: 25px;
	left: 25px;
	width:30px;
	height:25px;
	z-index: 1;
	-webkit-user-select: none;
	user-select: none;
	input {
		display: flex;
		width: 40px;
		height: 32px;
		position: absolute;
		cursor: pointer;
		opacity: 0;
		z-index: 2;
	}
	span.burgericon {
		display: flex;
		width: 29px;
		height: 2px;
		margin-bottom: 5px;
		position: relative;
		background-color: ezentrum_variables.$white;
		border-radius: 3px;
		z-index: 1;
		transform-origin: 5px 0px;
		transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
		background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
		opacity 0.55s ease;
	}
	span.burgericon:first-child {
		transform-origin: 0% 0%;
	}
	span.burgericon:nth-last-child(2) {
		transform-origin: 0% 100%;
	}
	input:checked ~ span.burgericon {
		opacity: 1;
		transform: rotate(45deg) translate(-3px, -1px);
		background: #36383F;
	}
	input:checked ~ span.burgericon:nth-last-child(3) {
		opacity: 0;
		transform: rotate(0deg) scale(0.2, 0.2);
	}
	input:checked ~ span.burgericon:nth-last-child(2) {
		transform: rotate(-45deg) translate(0, -1px);
	}
}

#ez_mobilemenu {
	display: block;
	@include ezentrum_mixins.for-size(tablet-landscape-up) {
		display:none;
	}
	position: static;
	overflow-x:hidden;
	overflow-y:scroll;
	margin: -50px 0 0 -25px;
	padding: ezentrum_variables.$global-padding;
	padding-top: 75px;
	line-height:2rem;
	box-shadow: unset;
	background-color: ezentrum_variables.$white;
	-webkit-font-smoothing: antialiased;
	transform-origin: 0% 0%;
	transform: translate(-100%, 0);
	transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	ul {
		list-style-type:none;
		margin:0;
		padding:0;
		li {
			padding: 10px 0;
			transition-delay: 2s;
			> a {
				font-weight:bold;
			}
			ul {
				list-style-type:none;
				padding:0;
				li {
					padding:0 0 0 10px;
					a {
						padding:ezentrum_variables.$global-padding;
						font-weight:normal;
					}
					ul {
						list-style-type:none;
						padding:0;
						li {
							padding:0 0 0 10px;
							a {
								padding:ezentrum_variables.$global-padding;
								font-weight:normal;
							}
						}
					}
				}
			}
			&.customer {
				.dropdown {
					.dropdown-toggle {
						display:block;
						font-weight:bold;
						i {
							margin-right:ezentrum_variables.$global-margin;
						}
					}
					.dropdown-toggle::after {
						display:block;
						border:none;
					}
					.dropdown-menu {
						position:relative;
						display:block;
						clear:both;
						float:unset;
						margin:0;
						padding:0;
						width:100%;
						border:none;
						a {
							padding:0 0 0 ezentrum_variables.$global-padding;
							color:ezentrum_variables.$primary-color;
						}
					}
				}
				.header_item.login {
					i {
						margin-right:ezentrum_variables.$global-margin;
					}
				}
			}
			&.ez_cartpreview {
				position:relative;
				border-bottom:1px solid ezentrum_variables.$primary-color;
				a {
					display:block;
					i {
						margin-right:ezentrum_variables.$global-margin;
					}
				}
				span.ez_cartpreview_positions {
					position:absolute;
					top:5px;
					right:50%;
					margin:0;
					padding:0 !important;
					width:0.8 * ezentrum_variables.$body-line-height;
					height:0.8 * ezentrum_variables.$body-line-height;
					border-radius:50%;
					@include ezentrum_mixins.small-text;
					text-align:center;
					font-weight:bold !important;
					background-color:#c60f13;
					color:ezentrum_variables.$white !important;
				}
			}
		}
	}
}

#ez_mobilemenu-toggle input:checked ~ div {
	transform: none;
	width:-moz-max-content;
	width:max-content;
	box-shadow: 0 0 10px #85888C;
}

/* only for testing */







